import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as PiLogoBlue } from "../assets/PureInsight_Logo_RGB_u1.svg";
import "../styles/PG4/pg4navbar.scss";
import { NavLink } from "react-router-dom";
import { SideBar } from "./PG4/Header/Sidebar";
import { Dropdown } from "./PG4/Header/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { __logout } from "../store/actions/auth";
import { clearCookie } from "../methods";
import { NavDropdown } from "react-bootstrap";

const NavigationBar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [showSidebar, setShowSidebar] = useState(true);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.auth.mfaEnabled);
  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  const userType = useSelector((state) => state.auth.userType);
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => console.log(state)
  );
  const currentPG2UserId = useSelector((state) => state.auth.currentPG2UserId);

  useEffect(() => {
    setActiveMenu(null);
    setShowSidebar(false);
  }, [location]);

  const logout = () => {
    dispatch(__logout(token, currentPractice, currentPracticeID)).then(
      (response) => {
        clearCookie();
        localStorage.clear();
        if (currentPG2UserId)
          window.location.href = process.env.REACT_APP_PG2_URL;
        else window.location.href = `${window.location.origin}/users/login`;
      }
    );
  };

  const toggleMenu = (menuId, isActive) => {
    setActiveMenu(isActive ? menuId : null);
  };

  return (
    <>
      <header className="pg4-navbar" aria-label="Main Navigation Bar">
        <div className="container-xxl">
          {/* Desktop Navigation */}
          <nav
            aria-label="Main Navigation"
            role="navigation"
            className="position-relative d-flex justify-content-between w-100 align-items-center"
          >
            <div className="logo mx-auto mx-lg-0" aria-label="Brand Logo">
              <NavLink to="/">
                <PiLogoBlue />
              </NavLink>
            </div>
            <a className="sr-only" href="#main-content">
              Skip to content
            </a>
            <ul className="links d-lg-flex d-none" role="menubar">
              <Dropdown
                label="Healthcare Providers"
                menuId="practitioner-menu"
                showMenu={activeMenu === "practitioner-menu"}
                toggleMenu={toggleMenu}
              >
                <NavLink
                  to="/hcp/about-pureinsight"
                  aria-label="Discover PureInsight"
                  className="pg4-dropdown-menu__item"
                  role="menuitem"
                >
                  Discover PureInsight
                </NavLink>
                <NavLink
                  to="/hcp/faqs"
                  aria-label="Healthcare Provider FAQs"
                  className="pg4-dropdown-menu__item"
                  role="menuitem"
                >
                  Healthcare Provider FAQs
                </NavLink>
                <NavLink
                  to="/hcp/registration"
                  aria-label="Healthcare Provider FAQs"
                  className="pg4-dropdown-menu__item"
                  role="menuitem"
                >
                  Practitioner Registration
                </NavLink>
              </Dropdown>
              <Dropdown
                label="Patients"
                menuId="patient-menu"
                showMenu={activeMenu === "patient-menu"}
                toggleMenu={toggleMenu}
              >
                <NavLink
                  to="/patient/why-pureinsight"
                  aria-label="Why PureInsight"
                  className="pg4-dropdown-menu__item"
                  role="menuitem"
                >
                  Why PureInsight
                </NavLink>
                <NavLink
                  to="/patient/faqs"
                  aria-label="Patient FAQs"
                  className="pg4-dropdown-menu__item"
                  role="menuitem"
                >
                  Patient FAQs
                </NavLink>
                <NavLink
                  to="/patient/self-register"
                  aria-label="Patient FAQs"
                  className="pg4-dropdown-menu__item"
                  role="menuitem"
                >
                  Patient Registration
                </NavLink>
              </Dropdown>
            </ul>

            <div
              className="account-actions d-lg-flex align-items-center d-none"
              role="toolbar"
              aria-label="Account Actions"
            >
              {isAuth && mfaEnabled ? (
                <NavLink onClick={logout} aria-label="Logout">
                  Logout
                </NavLink>
              ) : (
                <>
                  <ul className="links d-lg-flex d-none " role="menubar">
                    <Dropdown
                      label="Register"
                      menuId="register-menu"
                      showMenu={activeMenu === "register-menu"}
                      toggleMenu={toggleMenu}
                    >
                      <NavLink
                        to="/hcp/registration"
                        aria-label="Practitioner Registration"
                        className="pg4-dropdown-menu__item"
                        role="menuitem"
                      >
                        Practitioner Registration
                      </NavLink>
                      <NavLink
                        to="/patient/self-register"
                        aria-label="Patient Self Registration"
                        className="pg4-dropdown-menu__item"
                        role="menuitem"
                      >
                        Patient Registration
                      </NavLink>
                    </Dropdown>
                  </ul>
                </>
              )}
              {isAuth && mfaEnabled ? (
                <NavLink
                  to={`/${
                    userType === "AdminUser"
                      ? "admin"
                      : userType === "User"
                      ? "practice"
                      : "patient"
                  }/${userType === "Patient" ? "dashboard" : "dashboard"}`}
                  aria-label="Dashboard"
                  className="primary account-actions__link"
                >
                  {t("dashboard")}
                </NavLink>
              ) : (
                <NavLink
                  to="/users/login"
                  aria-label="Login"
                  className="primary account-actions__link"
                >
                  Sign in
                </NavLink>
              )}
            </div>
            <button
              className="sidebar-toggle d-block d-lg-none my-3"
              aria-label="Toggle Sidebar"
              aria-controls="sidebar"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <i className="fas fa-bars"></i>
            </button>
            <Link
              to="https://www.pureencapsulationspro.com/"
              className={`
              position-absolute fw-bold rounded-bottom pg4-btn text_primary 
              px-3 py-0 d-none d-lg-block text-decoration-none`}
              style={{
                right: 0,
                top: "100%",
                border: "1px solid #d9e0e9",
                background: "#f7f8fa",
                fontSize: "12px",
                lineHeight: 3,
                ":focus": {
                  display: "none",
                },
              }}
              target="_blank"
            >
              PURE ENCAPSULATIONS PRO SITE{" "}
              <i className="fas fa-arrow-right"></i>
            </Link>
          </nav>
        </div>
      </header>
      <SideBar
        show={showSidebar}
        logout={logout}
        onClose={() => setShowSidebar(false)}
      />
    </>
  );
};

export default NavigationBar;
